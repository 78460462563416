import React, { useEffect, useState, useContext } from "react";
import { DatePicker, Tabs, TimePicker, Select, Button } from "antd"; // Ensure Select is imported from antd
import ServiceType from "./servicetype"; // Default import
import moment from "moment";
import { AuthContext } from "contexts";
import { CompanySelect, MainTable } from "components"; // Named imports
import { instance } from "utils/axios";
import * as XLSX from "xlsx";
function Report() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const selectedHostpital=2
  // const [selectedHostpital, setSelectedHospital] = useState(hospitalId);
  const [date, setDate] = useState(moment().subtract(1, "days").startOf("day"));
  const [serviceTypes, setServiceTypes] = useState([]);
  const [data, setData] = useState([]);
  const [serviceType, setServiceType] = useState(1);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    fetchData();
  }, [date, serviceType]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/report/doctorservicecount?date=${moment(date).format(
        "YYYY-MM-DD"
      )}&servicetypeid=${serviceType}&hospital=${selectedHostpital}`,
    })
      .then((res) => {
   
        setData(res.data);
        if (res.data.length > 0) {
          generateColumns(res.data[0]);
        }
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const generateColumns = (data) => {
    const generatedColumns = Object.keys(data).map((key) => {
      if (key === "name") {
        return {
          title: "Нэр",
          dataIndex: key,
          key: key,
        };
      }
      return {
        title: key, // You can modify this to have more readable titles if necessary
        dataIndex: key,
        key: key,
        render: (text) => <div>{Intl.NumberFormat().format(text)}</div>,
        sorter: (a, b) => a[key] - b[key],
      };
    });
    setColumns(generatedColumns);
  };
  useEffect(() => {
    action.handleSidebarKey("report");
    instance({
      method: "get",
      url: `/servicetype`,
    })
      .then((res) => {
        let tmp = [];
        if (res.data.length != 1) {
          setServiceType(1);
        } else setServiceType(res.data[0].id);

        res.data.map((item) => {
          tmp.push({ label: item.name, value: item.id, text: item.name });
        });
        setServiceTypes(tmp);
      })
      .catch((err) => {});
  }, []);
  const handleExport = () => {
    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Generate a download link and trigger the download
    XLSX.writeFile(
      workbook,
      `report_${moment().format("YYYYMMDD_HHmmss")}.xlsx`
    );
  };
  return (
    <div className="p-4">
      <div className="flex gap-5 mb-3 ">
        {/* <CompanySelect
          value={selectedHostpital}
          onChange={(e) => {
            setSelectedHospital(e);
          }}
        /> */}
        <div>
          <DatePicker
            picker="month"
            value={date}
            onChange={(e) => setDate(e)}
          />
        </div>
        <div>
          <Select
            className="mb-0 w-[100px]"
            value={serviceType}
            options={serviceTypes}
            onChange={(e) => {
              setServiceType(e);
            }}
          />
        </div>
        <div className="mb-3">
          <Button type ="primary" onClick={handleExport}>Export</Button>
        </div>
      </div>
      <MainTable dataTable={data} columns={columns} title="Эмчийн тайлан" />
      {/* <Tabs items={items} /> */}
    </div>
  );
}

export default Report;

import React, { useEffect, useState, useContext, useRef } from "react";
import { Modal, Form as AntForm, DatePicker, message } from "antd";
import { Form, MainTable, PrintComponent } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import columns from "./columns";
import moment from "moment";
import { AuthContext } from "contexts";
import physicianField from "./physicianField";
import technicianField from "./technicianField";

function PETBooking() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id;
  const [openPrint, setOpenPrint] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [openTechnician, setOpenTechnician] = useState(false);
  const [technicianData, setTechnicianData] = useState(null);
  const [services, setServices] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [physicianData, setPhysicianData] = useState(null);
  const [openPhysician, setOpenPhysician] = useState(false);
  const [form] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();

  useEffect(() => {
    action.handleSidebarKey("pet-booking");
    fetchData();
  }, [startDate,endDate]);
  useEffect(() => {
    instance({
      method: "get",
      url: `/service?hospital=2`,
    })
      .then((res) => {
        setServices(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {});
    instance({
      method: "get",
      url: `/petdoctors`,
    })
      .then((res) => {
        setDoctors(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)} ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {});
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/petbookings?startDate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(endDate).format("YYYY-MM-DD")}`,
    })
      .then((res) => {
        setData(res.data);

        if (editData) {
          setEditData(res.data.find((item) => item.id === editData.id));
        }
      })
      .catch((err) => {})
      .then(() => setLoading(false));

    setLoading(true);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (role == "Эмч") {
      values.bookingData.petDoctorId = userId;
    }
    if (!editData.isAdd) {
      instance({
        method: "put",
        url: "/petbooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
            customerId: editData.bookingData.customerId,
            id: editData.bookingData.id,
          },
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/petbooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
          },
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };
  const handleCancel = (values) => {
    if (values.status != 0) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээг цуцлах боломжгүй байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Шинжилгээг цуцлахдаа итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id

          instance({
            method: "put",
            url: `/bookingservice/cancel/${values.id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handleSetDoctor = (value, row, first) => {

    var data = first
      ? {
          id: row.id,
          doctorId: value ?? null,
          servicetypeid: row.servicetypeid,
        }
      : {
          id: row.id,
          doctor2Id: value ?? null,
          servicetypeid: row.servicetypeid,
        };
    instance({
      method: "put",
      url: `/booking/setdoctor`,
      data: data,
    })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        if (err.response.status == 403) {
          modal.warning({
            title: "Анхаарна уу !",
            content: "Хэрэглэгчийн эрх хүрэхгүй байна",
          });
        }
      });
  };
  const handleResponse = (values) => {
    if (values.doctorId != userId) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Өөр эмчид хариу хуваарьлагдсан эсвэл хоосон байна.",
      });
      return;
    } else {
      modal.confirm({
        title: "Анхаарна уу !",
        content: "Хариу гарсан гэдэгт итгэлтэй байна уу",
        okText: "Тийм",
        cancelText: "Үгүй",
        onOk() {
          return new Promise((resolve, reject) => {
            //  r/:id
            instance({
              method: "post",
              url: `/bookingservice/doctor/${values.id}`,
            })
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                if (err.response.status == 403) {
                  modal.warning({
                    title: "Анхаарна уу !",
                    content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                  });
                }
                reject();
              })
              .then(() => {
                fetchData();
              });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    }
  };
  const handleAdd = () => {
    let data = {
      isAdd: true,
      bookingData: {
        saleType: "normal",
        currentDate: startDate,
        servicetypeid: 3,
      },
    };
    setEditData(data);
    setOpen(true);
  };

  const handleEdit = (row) => {
    if (row.status != 0) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Хариу гарсан тул Шинжилгээний мэдээлэл өөрчлөх боломжгүй.",
      });
      return;
    }
    setEditData({
      isAdd: false,
      customerData: {
        id: row.customerId,
        firstname: row.firstname,
        lastname: row.lastname,
        regno: row.regno,
        age: row.age,
        gender: row.gender,
        hasAllegry: row.hasAllegry,
      },
      bookingData: row,
    });
    setOpen(true);
  };
  const handleOpenPhysician = (row) => {
    let timeOfInjection;
    if (!row.timeOfInjection) {
      if (row.npTime) {
        timeOfInjection = moment(row.npTime);
        timeOfInjection.add(1, "minutes");
      } else {
        timeOfInjection = new Date(startDate);
      }
    }
    setPhysicianData({
      id: row.id,
      npTime: row.npTime ?? new Date(startDate),
      npDoseMCi: row.npDoseMCi,
      npDoseMBq: row.npDoseMBq,
      timeOfInjection: row.timeOfInjection ?? timeOfInjection,
    });
    setOpenPhysician(true);
  };
  const handleOpenTechnician = (row) => {
    console.log(row);
    setTechnicianData({
      id: row.id,
      npTime: row.npTime ?? new Date(startDate),
      currentDateTime: row.currentDateTime ?? new Date(startDate),
      // timeOfInjection: row.timeOfInjection ?? new Date(startDate),
    });
    setOpenTechnician(true);
  };
  const handlePhysician = (values) => {
    setLoading(true);
    instance({
      method: "put",
      url: "/pet-physician",
      data: {
        ...values,
        id: physicianData.id,
      },
    })
      .then((res) => {
        setOpenPhysician(false);
        form.resetFields();
        fetchData();
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const handleTechnician = (values) => {
    setLoading(true);
    const date = moment(values.currentDateTime);
    var duration = moment.duration(date.diff(values.npTime));

    var minutes = duration.asMinutes();
    values.sumNPTime = minutes;

    instance({
      method: "put",
      url: "/setTechnician",
      data: {
        ...values,
        id: technicianData.id,
      },
    })
      .then((res) => {
        setOpenTechnician(false);
        form.resetFields();
        fetchData();
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleRefresh = () => {
    fetchData();
  };
  return (
    <div className="px-1  md:px-5">
      <div className="col-span-12 md:col-span-6  md:items-center ">
        <div className="flex items-center ">
          <DatePicker
            className="w-[140px]"
            value={startDate}
            onChange={(e) => setStartDate(e)}
          />

          <DatePicker
            className="w-[140px]"
            value={endDate}
            onChange={(e) => setEndDate(e)}
          />
        </div>
      </div>
      {/* </div> */}

      <MainTable
        dataTable={data}
        columns={columns({
          currentPage,
          doctors,
          handleSetDoctor,
          role,
          handleResponse,
          handleCancel,
          handleEdit,
          handleOpenPhysician,
          handleOpenTechnician,
          messageApi,
          openPrint: (row) => {
            setEditData({
              hospitalId: 2,
              name: `${row.customerName}`,
              serviceName: `${row.serviceNames} /PET/`,
            });
            setOpenPrint(true);
          },
        }).filter((col) => col.visible != false)}
        setCurrentPage={setCurrentPage}
        loading={loading}
        scroll={{ y: "calc(100vh - 320px)" }}
        title={`PET Шинжилгээ /${moment(startDate).format("YYYY-MM-DD")}/`}
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              if (role === "Техникч") {
                handleOpenTechnician(record);
              } else if (role === "Physician") {
                handleOpenPhysician(record);
              } else {
                handleEdit(record);
              }
            },
          };
        }}
      />
      <Modal
        title={"Шинжилгээ"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({
            customers,
            form,
            services,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>

      <Modal
        title={"Physician"}
        open={openPhysician}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={
          loading
            ? null
            : () => {
                setOpenPhysician(false);
                form.resetFields();
              }
        }
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={physicianField({ form })}
          layout="vertical"
          onFinish={handlePhysician}
          editData={physicianData}
          className="gap-4"
        />
      </Modal>
      <PrintComponent
        openPrint={openPrint}
        data={editData}
        setOpenPrint={setOpenPrint}
      ></PrintComponent>
      <Modal
        title={"Техникч"}
        open={openTechnician}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={
          loading
            ? null
            : () => {
                setOpenTechnician(false);
                form.resetFields();
              }
        }
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={technicianField()}
          layout="vertical"
          onFinish={handleTechnician}
          editData={technicianData}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
      {messageHolder}
    </div>
  );
}

export default PETBooking;
